/**
 * Renders a white div with a large shadow that displays child content.
 */
/**  @jsx jsx */
import { css, jsx } from '@emotion/core'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import ContentLoader from 'react-content-loader';
import {
  breakpoints,
  colors,
  durations,
  shadows,
  unitConverter as uc,
} from '../../../styles/base';

const CardSkeleton = () => (
  <ContentLoader
    width={300}
    height={250}
    speed={2}
    primaryColor={colors.lightGray.one}
    secondaryColor={colors.lightGray.two}
  >
    <rect x="0" y="0" rx="10" ry="0" width="300" height="120" />
    <rect x="10" y="140" rx="4" ry="4" width="200" height="16" />
  </ContentLoader>
);

const isTest = process.env.NODE_ENV === 'test';

/**
 * Card Component
 */
const Card = ({
  backgroundColor,
  boxShadow,
  cardSkeleton,
  children,
  hoverStyles,
  id,
  lazyLoad,
  ...props
}) => {
  const cardCss = css`
    background-color: ${backgroundColor};
    border-radius: ${uc('50px')};
    box-shadow: ${boxShadow};
    transition: all ${durations.medium} ease-in-out;

    &:hover {
      ${hoverStyles}
    }

    @media (${breakpoints.mobile}) {
      ${hoverStyles}
    }
  `;

  const cardCore = (
    <div css={cardCss} {...props}>
      {children}
    </div>
  );

  return !isTest && lazyLoad ? (
    <LazyLoadComponent
      threshold={500}
      placeholder={
        <div css={cardCss} id={id}>
          {cardSkeleton || <CardSkeleton />}
        </div>
      }
    >
      {cardCore}
    </LazyLoadComponent>
  ) : (
    cardCore
  );
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundColor: PropTypes.string,
  boxShadow: PropTypes.string,
  cardSkeleton: PropTypes.element,
  hoverStyles: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  id: PropTypes.string,
  lazyLoad: PropTypes.bool,
};

Card.defaultProps = {
  backgroundColor: colors.white,
  boxShadow: shadows.large,
  cardSkeleton: <CardSkeleton />,
  hoverStyles: '',
  id: '',
  lazyLoad: true,
};

export default Card;

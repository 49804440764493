import PropTypes from 'prop-types';
/** @jsx jsx needed to get Storybook to render */
import { css, jsx } from '@emotion/core';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Heading2 } from '../../atoms/Headings/Headings';
import Subheading from '../../atoms/Subheading/Subheading';
import {
  breakpoints,
  colors,
  durations,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  unitConverter as uc,
} from '../../../styles/base';
import CarouselCard from '../../molecules/CarouselCard/CarouselCard';
import Text from '../../atoms/Text/Text';
import Section from '../../molecules/Section/Section';

const CarouselSection = ({
  carouselCards,
  footnote,
  sectionHeading,
  sectionStyles,
  sectionSubHeading,
}) => {
  const slideWidth = 1430;

  const slideWidthInRems = uc(`${slideWidth}px`);
  const sectionCss = css`
    position: relative;
    left: calc(50% - (${slideWidthInRems} / 2));
    width: ${slideWidthInRems};

    .slick-list {
      overflow: visible;
    }

    .slick-slide {
      display: flex;
      padding: ${uc('50px 0')};

      .carousel-card {
        &::after {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background: ${colors.lightGray.one};
          transition: ${durations.medium};
          pointer-events: none;
          content: '';
        }
      }

      &.slick-current {
        .carousel-card {
          &::after {
            opacity: 0;
          }
        }
      }

      > div {
        display: inline-block;
        margin: auto;
      }
    }

    .slick-dots {
      top: ${uc('-8px')};
      bottom: auto;

      button {
        width: auto;
        height: auto;
        color: ${colors.primary};
        font-weight: ${fontWeights.semiBold};
        font-size: ${fontSizes.twenty};
        font-family: ${fontFamilies.proximaSoft};
        line-height: 1;

        &::before {
          display: none;
        }
      }

      li {
        width: auto;
        height: auto;
        margin: ${uc('0 15px')};
        vertical-align: middle;

        &.slick-active button {
          color: ${colors.accent};
        }
      }
    }

    @media (${breakpoints.largeTablet}) {
      left: 0;
      width: 100%;

      ul {
        display: flex;
        width: 100%;
        overflow-x: scroll;
        overflow-y: visible;
      }

      li {
        height: ${uc('80px')};

        &::after {
          bottom: ${uc('8px')};
        }
      }

      img {
        height: ${uc('50px')};
      }
    }
  `;

  const heading2Css = css`
    width: 55%;
    margin: ${uc('0 auto 64px')};
    color: ${colors.blueBlack};
    font-weight: ${fontWeights.semiBold};
    line-height: ${lineHeights.copy};
    text-align: center;

    @media (${breakpoints.largeTablet}) {
      width: 60%;
    }

    @media (${breakpoints.tablet}) {
      width: 70%;
    }

    @media (${breakpoints.mobile}) {
      width: 100%;
    }
  `;

  const subHeadingCss = css`
    max-width: ${uc('725px')};
    margin: ${uc('0 auto 64px')};
    margin-top: ${uc('-30px')};
    margin-bottom: ${uc('130px')};
    color: ${colors.white};
    font-weight: ${fontWeights.semiBold};
    font-family: ${fontFamilies.proximaSoft};
    line-height: ${lineHeights.copy};
    text-align: center;
  `;

  const footnoteCss = css`
    margin-left: ${uc('20px')};
    color: ${colors.lightGray.three};
    font-style: italic;
  `;
  const customDots = carouselCards.map(card => card.headingText);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToScroll: 1,
    initialSlide: 0,
    slidesToShow: 1,
    arrows: false,
    className: customDots.length === 1 ? 'slick-active' : '',
    autoplay: true,
    pauseOnDotsHover: true,
    pauseOnFocus: true,
    appendDots: dots => (
      <div>
        <ul> {dots} </ul>
      </div>
    ),
    customPaging: i => <button type="button">{customDots[i]}</button>,
  };

  const renderedCards = carouselCards.map(card => (
    <CarouselCard key={card._key} {...card} {...sectionStyles} />
  ));

  return (
    <Section sectionStyles={sectionStyles}>
      <div css={sectionCss}>
        <Heading2 fontSize={fontSizes.thirty} css={heading2Css}>
          {sectionHeading}
        </Heading2>
        <Subheading
          fontSize={fontSizes.twentyFive}
          css={subHeadingCss}
          subHeadingCss
        >
          {sectionSubHeading}
        </Subheading>
        <Slider {...sliderSettings}>{renderedCards}</Slider>
        {footnote && <Text css={footnoteCss}>{footnote}</Text>}
      </div>
    </Section>
  );
};

CarouselSection.propTypes = {
  carouselCards: PropTypes.arrayOf(PropTypes.shape({})),
  footnote: PropTypes.string,
  sectionHeading: PropTypes.string,
  sectionStyles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  sectionSubHeading: PropTypes.string,
};

CarouselSection.defaultProps = {
  carouselCards: [],
  footnote: '',
  sectionHeading: '',
  sectionStyles: {},
  sectionSubHeading: '',
};

export default CarouselSection;

import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { colors, fontFamilies, fontSizes } from '../../../styles/base';
import Text from '../Text/Text';

const Subheading = styled(Text)`
  color: ${props => props.color};
  font-size: ${props => props.fontSize};
  font-family: ${fontFamilies.roboto};
`;

Subheading.propTypes = {
  color: PropTypes.string,
  fontSize: PropTypes.string,
};

Subheading.defaultProps = {
  color: colors.primary,
  fontSize: fontSizes.fourteen,
};

export default Subheading;
